.submit-ticket-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns on desktop */
  grid-gap: 10px; /* Space between elements */
  max-width: 600px; /* Control the width of the grid */
  margin: 0 auto; /* Center the grid horizontally */
}

.submit-ticket-date {
  grid-column: span 4; /* Make the date span all 4 columns */
  font-size: 16px;
  color: black; /* Set the date text color to black */
  text-align: center; /* Center the text */
}

.description-label {
  grid-column: span 4; /* Make the label span all 4 columns */
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
  text-align: center; /* Center the text */
}

.button-container {
  grid-column: span 4; /* Make the button container span all 4 columns */
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for the buttons */
  grid-gap: 10px; /* Space between buttons */
}

.submit-ticket-button {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 16px;
  text-align: center;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.submit-ticket-button:hover {
  background-color: #e0e0e0;
}

.submit-ticket-button.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.additional-info-container {
  grid-column: span 4; /* Make the additional info container span all 4 columns */
  margin-top: 20px; /* Space above the additional info section */
}

.description-textarea {
  width: 100%; /* Full width of the container */
  padding: 10px; /* Padding on all sides */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical; /* Allows the user to resize the textarea vertically */
  min-height: 100px; /* Minimum height of the text area */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.submit-button {
  grid-column: span 4; /* Make the submit button span all 4 columns */
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .submit-ticket-grid {
    grid-template-columns: 1fr; /* Single column on tablets and smaller screens */
  }

  .button-container {
    grid-template-columns: 1fr; /* Buttons stack vertically */
  }

  .additional-info-container {
    grid-column: span 4;
  }
  
  .description-textarea,
  .submit-ticket-button,
  .submit-ticket-button-container {
    grid-column: span 4; /* Ensure elements take full width on small screens */
  }

  .submit-ticket-date,
  .description-label {
    grid-column: span 1; /* Ensure labels take full width on small screens */
  }
}

@media (max-width: 480px) {
  .submit-ticket-grid {
    grid-template-columns: 1fr; /* Single column on mobile */
    grid-gap: 6px; /* Adjust gap for smaller screens */
  }

  .button-container {
    grid-template-columns: 1fr; /* Single column for buttons on mobile */
  }

  .description-textarea,
  .submit-ticket-button,
  .submit-ticket-button-container,
  .submit-ticket-date,
  .description-label {
    grid-column: span 1; /* Make elements span full width */
  }
}
