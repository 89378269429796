header {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #123;
    color: white;
    padding: 0;
}

.main-heading {
    max-width: 800px;
    text-align: center;
    margin: auto;
}

.main-heading h1 {
    font-size: 50px;
}

.main-heading p {
    font-size: 18px;
    line-height: 26px;
}

.header {
    overflow: hidden;
    background-color: #0b1622;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header img {
    width: 100px;
    margin-right: 20px; /* Space between the logo and the links */
}

.header a {
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}

/* input[type="text"] {
    padding: 8px 20px;
    border-radius: 50px;
    background-color: #123;
    border-color: white;
    margin-right: 30px;
    border: 0;
} */

.rounded-button {
    padding: 10px 30px;
    border-radius: 50px;
    font-weight: bold;
    margin-left: 20px;
}

.header a.logo {
    font-size: 25px;
    font-weight: bold;
    padding: 0 !important;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 10px; /* Add space between items */
}

@media screen and (max-width: 768px) {
    .main-heading h1 {
        font-size: 35px; /* Smaller font size for mobile */
    }

    .main-heading p {
        font-size: 16px;
        line-height: 22px;
    }

    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .header img {
        width: 80px; /* Smaller logo size for mobile */
        margin-bottom: 10px;
    }

    .header a {
        float: none;
        display: block;
        text-align: left;
        font-size: 16px; /* Smaller font size for mobile */
    }

    .header-right {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 0; /* Reset gap for mobile */
    }

    .rounded-button {
        margin-left: 0;
        margin-top: 10px; /* Add space above the button on mobile */
        width: 100%; /* Full-width button on mobile */
    }

    input[type="text"] {
        width: 100%; /* Full-width input field on mobile */
        margin-right: 0;
        margin-bottom: 10px; /* Add space below the input field on mobile */
    }
}
