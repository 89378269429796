/* Modal Overlay */
.modal-overlay {
    position: fixed; /* Fixes the position relative to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex; /* Use flexbox to center the modal */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    overflow: auto; /* Allow scrolling if content overflows */
    z-index: 1000; /* Ensure it's on top of other content */
}

/* Modal Content */
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 90vw; /* 90% of the viewport width */
    max-width: 600px; /* Optional: Set a max width to avoid too large modals */
    max-height: 90vh; /* 90% of the viewport height */
    overflow-y: auto; /* Allow scrolling if content is too tall */
    box-sizing: border-box; /* Include padding in width/height */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .modal-overlay {
        /* Ensure the overlay covers the viewport correctly on mobile */
        width: 100vw;
        height: 100vh;
    }

    .modal-content {
        width: 90vw; /* 90% of the viewport width */
        max-width: none; /* Remove max-width on smaller screens */
        height: auto; /* Adjust height based on content */
        max-height: 90vh; /* Ensure it doesn’t exceed 90% of viewport height */
    }
}

/* Additional Styling */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
    color: #000;
}

/* Modal image styling */
.modal-image {
    max-width: 100%;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
}

/* Additional Modal Styles */
.modal-notes {
    width: 100%; /* Take full width */
    height: 150px; /* Set a reasonable height */
    padding: 10px; /* Add padding */
    box-sizing: border-box; /* Include padding in width/height */
}

.modal-image-input {
    width: 100%; /* Take full width */
    margin-top: 10px; /* Add margin-top */
}

.modal-buttons {
    display: flex;
    justify-content: space-between; /* Space buttons evenly */
    margin-top: 20px; /* Add margin-top */
}

.modal-submit-button,
.modal-cancel-button {
    padding: 10px 20px; /* Add padding */
    border: none;
    border-radius: 4px; /* Rounded corners */
    cursor: pointer;
}
